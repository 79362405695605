/**
 * Thermolabo Scanner loader and data submissions.
 * Version : 1.0
 * Date: 18/01/23
 */
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { useParams, useLocation, Navigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import Shock from './Shock/Scanner';
import Roll from './Roll/Scanner';
import Photo from './Photo';
import './Scan.css';
import './bulma.min.css';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}
function withLocation(Component) {
    return props => <Component {...props} location={useLocation()} />;
}


class ScanShock extends Component {

    constructor(props) {
        super(props);
        this.cancel = this.cancel.bind(this);
        this.continue = this.continue.bind(this);
        this.back = this.back.bind(this);
        this.submitComment = this.submitComment.bind(this);
        this.addPhoto = this.addPhoto.bind(this);
        this.removePhoto = this.removePhoto.bind(this);
        this.validDetection = this.validDetection.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.checkBarcode = this.checkBarcode.bind(this);
        this.submitFormDatas = this.submitFormDatas.bind(this);
        this.state = {
            coords: props.location.state == null ? false :props.location.state.coords,
            result: null,
            step : 0,
            comment:'',
            photos:[],
            email:'',
            isLoading: false
        };
        this.commentField = React.createRef();
        this.emailField = React.createRef();
    }
    
    /**
     * Scanner callback method after detection
     * @param {*} result 
     */
    _onDetected = result => {
        const reg = /^[A-Z]{2}[0-9]{8}$/i;
        if (reg.test(result.codeResult.code)) {
            this.setState({ result: result });
            if (result.isManual) {
                if (result.isOnError) {
                    this.setState({ step: 2 });
                } else {
                    this.setState({ step: 4 });
                }
            } else {
                this.setState({ step: 1 });
            }
        } else {
            this.setState({ step: 9 });
        }
    }
    async checkBarcode(){
        this.setState({isLoading: true});
        let isOk = false;
        const uuid = sessionStorage.getItem("uuid");
        await fetch('https://thermolabo.mmdev.fr' + '/barcode', {
            method: 'POST',
            body: JSON.stringify({
                uuid: uuid,
                code: this.state.result.codeResult.code,
                type: this.props.params.type,
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
        .then((response) => response.json())
        .then((result) => {
            isOk = result.exist;
        })
        .catch((err) => {
            console.log(err.message);
        });
        this.setState({isLoading: false});
        return isOk;
    }
    submitForm(){
        const email = this.emailField.current;
        const value = email.value;
        const emailreg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        let submit = true;
        const alertMsg = document.querySelector('.mailfield .help');
        if (value === '' && (this.state.comment !== '' || this.state.photos.length > 0)) {
            email.classList.add('is-danger');
            alertMsg.textContent = this.props.t('EmailRequiredAlert');
            submit = false;
        } else if (value !== '' && !emailreg.test(value)) {
            email.classList.add('is-danger');
            alertMsg.textContent = this.props.t('EmailErrorAlert');
            submit = false;
        }
        if (submit) {
            this.setState({email: value});
            this.setState({isLoading: true});
            /*setTimeout(() => {
                this.setState({isLoading: false});
                this.continue();
            }, 1000);*/
            this.submitFormDatas(value);
        }
    }
    async submitFormDatas(email){
        this.setState({isLoading: true});
        let isOk = false;
        const uuid = sessionStorage.getItem("uuid");
        console.log(this.state);
        await fetch('https://thermolabo.mmdev.fr' + '/barcode/submit', {
            method: 'POST',
            body: JSON.stringify({
                uuid: uuid,
                code: this.state.result.codeResult.code,
                type: this.props.params.type,
                onError: this.state.result.isOnError,
                getCodeMethod: this.state.result.isManual,
                scanPhoto: this.state.result.image,
                comment: this.state.comment,
                photos: this.state.photos,
                email: email,
                coords: this.state.coords,
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
        .then((response) => response.json())
        .then((result) => {
            console.log(result);
            isOk = result.submitted;
            if (isOk) {
                this.setState({step: 5});
            } else {
                this.setState({step: 6});
            }
        })
        .catch((err) => {
            console.log(err);
            this.setState({step: 6});
        });
        this.setState({isLoading: false});
    }
    /**
     * Reset scanning process
     */
    cancel(){
        this.setState({ step: 0 });
    }
    /**
     * Go to next process step
     */
    continue(){
        this.setState({ step: this.state.step + 1 });
    }
    /**
     * Go to previous process step
     */
    back(){
        this.setState({ step: this.state.step - 1 });
    }
    /**
     * Go to next process step
     */
    validDetection(){
        if (this.state.result.isOnError) {
            this.setState({ step: this.state.step + 1 });
        } else {
            this.setState({ step: 4 })
        }
    }
    submitComment(){
        const comment = this.commentField.current.value;
        this.setState({comment: comment});
        this.continue();
    }
    addPhoto(){
        this.setState({ step: 8 })
    }
    _onPhotoTaken = photo => {
        let photos = this.state.photos;
        photos.push(photo);
        this.setState({ photos: photos });
        this.setState({ step: 3 });
    }
    removePhoto(e){
        const elmt = e.target;
        const index = parseInt(elmt.dataset.index);
        const photos = this.state.photos.filter((photo, i) => {
            return i !== index;
        });
        this.setState({photos: photos});
        
    }
    
    render(){
        if (!this.state.coords) {
            return (
                <Navigate to="/welcome" replace={true} />
            );
        }
        if (this.state.isLoading) {
            return (
                <div className='Scan-Loading'>
                    <div className="container">
                        <h1><Trans>UploadTitle</Trans></h1>
                        <div className='App-Loader'></div>
                    </div>
                    <div className='Landscape'></div>
                </div>
            );
        }
        const ScannerType = this.props.params.type;
        let Scanner = '';
        switch (ScannerType) {
            case 'shock':
                Scanner = <Shock onDetected={this._onDetected} />;
                break;
            case 'roll':
                Scanner = <Roll onDetected={this._onDetected} />;
                break;
            default:
                Scanner = '';
        }
        switch (this.state.step) {
            case 0:
                //Load scanner
                return (
                    <div className="Scan">
                        { Scanner }
                        <div className='Landscape'></div>
                    </div>
                );
            case 1:
                //Confirm Barcode and status reading
                const barcode = this.state.result.codeResult.code;
                const OnError = this.state.result.isOnError;
                const status = OnError ? <Trans>RedSensor</Trans> : <Trans>WhiteSensor</Trans>;
                const statusClass = OnError ? 'ko' : 'ok';
                const containerClassName = 'container ' + statusClass;
                return (
                    <div className='Scan-results'>
                        <div className={ containerClassName }>
                            {OnError ? (
                            <h1><Trans>ConfirmErrorTitle</Trans></h1>
                            ):(
                            <h1><Trans>ConfirmTitle</Trans></h1>
                            )}
                            <div className='Scan-message'>
                                <p><Trans>ConfirmIntro</Trans></p>
                                <ul>
                                    <li className={ statusClass }>
                                        <span><Trans>ConfirmBarcode</Trans></span> : { barcode }
                                    </li>
                                    <li>
                                        <span><Trans>ConfirmStatus</Trans></span> : { status }
                                    </li>
                                </ul>
                                <div className='actions row'>
                                    <button className='continue' onClick={this.validDetection}>
                                        <Trans>ConfirmButtonYes</Trans>
                                    </button>
                                    <button onClick={this.cancel}>
                                        <Trans>ConfirmButtonNo</Trans>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='Landscape'></div>
                    </div>
                );
            case 2:
                //Add a comment
                return (
                    <div className='Scan-Comment'>
                        <div className="container">
                            <h1><Trans>CommentTitle</Trans></h1>
                            <div className='Scan-message'>
                                <p><Trans>CommentIntro</Trans></p>
                                <p><Trans>CommentIntro2</Trans></p>
                                <div className="field">
                                    <div className="control">
                                        <textarea className="textarea" placeholder={this.props.t('CommentPlaceholder')} ref={this.commentField}>{this.state.comment}</textarea>
                                    </div>
                                </div>
                                <div className='actions solo'>
                                    <button className='continue' onClick={this.submitComment}>
                                        <Trans>ButtonNextStep</Trans>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='Landscape'></div>
                    </div>
                );
            case 3:
                //Add photos
                return (
                    <div className='Scan-Photos'>
                        <div className="container">
                            <h1><Trans>PhotosTitle</Trans></h1>
                            <div className='Scan-photos'>
                                <p><Trans>PhotosIntro</Trans></p>
                                <div className="Photos-list">
                                {this.state.photos.map((photo, index) => (
                                    <div className='Photo'>
                                        <img src={photo} alt={ 'Product Photo ' + index } />
                                        <button className='Photo-Remove' data-index={index} onClick={this.removePhoto}>
                                            <Trans>RemovePhoto</Trans>
                                        </button>
                                    </div>
                                ))}
                                </div>
                                { (this.state.photos.length < 3) ?
                                <button className='Photos-add' onClick={this.addPhoto}>
                                    <Trans>AddPhoto</Trans>
                                </button>
                                :
                                <div className='Photos-max'>
                                </div>
                                }
                                <div className='actions '>
                                    <button className='back' onClick={this.back}>
                                        <Trans>ButtonBack</Trans>
                                    </button>
                                    <button className='continue' onClick={this.continue}>
                                        <Trans>ButtonNextStep</Trans>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='Landscape'></div>
                    </div>
                );
            case 4:
                //Add a Email address required if there's comment or photos
                return (
                    <div className='Scan-Comment'>
                        <div className="container">
                            <h1><Trans>EmailTitle</Trans></h1>
                            <div className='Scan-message'>
                                <p><Trans>EmailIntro</Trans></p>
                                <div className="field mailfield">
                                    <div className="control has-icons-left has-icons-right">
                                        <input className="input" type="email" ref={this.emailField} />
                                        <span className="icon is-small is-left">
                                        <FontAwesomeIcon icon={faEnvelope} />
                                        </span>
                                    </div>
                                    <p className="help is-danger"></p>
                                </div>
                                { this.state.result.isOnError ? 
                                (
                                <div className='actions'>
                                    <button className='back' onClick={this.back}>
                                        <Trans>ButtonBack</Trans>
                                    </button>
                                    <button className='continue' onClick={this.submitForm}>
                                        <Trans>ButtonFinalSubmit</Trans>
                                    </button>
                                </div>
                                ) : (
                                <div className='actions solo'>
                                    <button className='continue' onClick={this.submitForm}>
                                        <Trans>ButtonFinalSubmit</Trans>
                                    </button>
                                </div>
                                )}
                            </div>
                        </div>
                        <div className='Landscape'></div>
                    </div>
                );
            case 5:
                return (
                    <div className='Scan-Comment'>
                        <div className="container">
                            <h1><Trans>ThanksTitle</Trans></h1>
                            <p><Trans>ThanksDesc</Trans></p>
                        </div>
                        <div className='Landscape'></div>
                    </div>
                );
            case 6:
                //Redirect to product choice
                return (
                    <Navigate to="/welcome" state={{step: 2, coords: this.state.coords}} replace={true} />
                );
            case 8:
                //Take a photo
                return (
                    <Photo onTaken={this._onPhotoTaken}/>
                );
            case 9 :
                //Error screen if barcode malformed or not recognized
                return (
                    <div className='Scan-results'>
                        <div className='container ko'>
                            <h1><Trans>ErrorScanTitle</Trans></h1>
                            <div className='Scan-message'>
                                <p><Trans>ErrorScanText</Trans></p>
                                <div className='actions solo'>
                                    <button className='continue' onClick={this.cancel}>
                                        <Trans>ButtonRetry</Trans>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='Landscape'></div>
                    </div>
                );
            default:
                return false;
        }
    }
}

export default withTranslation()(withLocation(withParams(ScanShock)));
